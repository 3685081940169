export default {
  firstName: '',
  lastName: '',
  organization: '',
  jobTitle: '',
  email: '',
  phone: '',
  country: '',
  addressLine1: '',
  addressLine2: '',
  postalCode: '',
  city: '',
  state: '',
  vatNumber: '',
  consentDataProcessing: false,
  consentTermsAndConditions: false,
}
