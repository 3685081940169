<template>
  <div class="row">
    <div class="col-12">
      <div v-if="ticket() && ticket().potentialDiscount" class="ticket-banner">
        <p class="display-2 bold white mb-1">
          Save up to {{ ticket().currency }}{{ ticket().potentialDiscount }},-
          per ticket!
        </p>
        <p v-if="ticket().isVeryEarlyBird" class="display-4 white mb-1">
          {{ ticket().category }} rates are temporarily available.
        </p>
        <p v-else class="display-4 white mb-1">
          {{ ticket().category }} rates will expire on {{ ticket().saleEndsAt }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 order-2 order-md-1 pe-5">
      <h3 class="display-3 bold margin-2 blue-light">
        This is what you will receive
      </h3>

      <ul>
        <li>
          Your ticket to <b>{{ event.title }}</b>
        </li>
      </ul>
      <div v-if="event.ticketInfo" v-html="event.ticketInfo" class="mb-4"></div>
      <div v-else>
        <ul>
          <li>
            Access to all conference sessions in <b>{{ event.location }}</b>
          </li>
          <li>
            Extensive networking opportunities with
            <b>{{ event.attendeeTargetCount }}+</b> professionals
          </li>
          <li class="margin-2">
            Access to presentation slides after the event
          </li>
        </ul>
      </div>

      <div v-if="availableAddons().length > 0">
        <h3 class="display-3 bold margin-1 blue-light">Optional add-ons</h3>
        <p>Add-ons can be selected later in the registration process.</p>
        <div
          v-for="product in availableAddons()"
          :key="product.id"
          class="row add-on-product ms-0 ps-0"
        >
          <div
            v-if="product.image"
            class="col-4 visual"
            :style="{ backgroundImage: `url(${product.image})` }"
          ></div>
          <div
            v-else
            class="col-4 visual"
            :style="{ backgroundImage: `url(${urls.addOnDefaultImage})` }"
          ></div>
          <div class="col-8 content">
            <p class="display-5 bold blue-light mb-1">{{ product.name }}</p>
            <p v-if="product.description" class="mb-1">
              {{ product.description }}
            </p>
            <p v-if="product.soldOut" class="display-6 red margin-0">
              Sold out
            </p>
            <p v-else class="display-6 green bold margin-0">
              {{ product.currency }}{{ product.price }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="event.preferredRatesInfo">
        <h3 class="display-3 bold margin-1 blue-light mt-5">Preferred Rates</h3>
        <div v-html="event.preferredRatesInfo"></div>
      </div>
    </div>

    <div class="col-12 col-md-6 order-1 order-md-2">
      <h3 class="display-3 margin-2 bold blue-light">{{ currentStepLabel }}</h3>
      <table class="product-selection">
        <template v-for="product in availableTickets()" :key="product.id">
          <div class="product-row">
            <tr>
              <td class="product-title large">
                <span class="large bold blue-light"
                  >{{ product.category }}:</span
                >
                {{ product.name
                }}<span v-if="product.countries.length > 0"> *</span><br />
                <span class="small blue-light">{{ event.title }}</span
                ><br />
                <a
                  v-if="product.isVirtual"
                  class="small"
                  :href="urls.virtualTicket"
                >
                  Learn more ></a
                >
              </td>
              <td class="product-amount large">
                <select
                  v-if="!product.soldOut"
                  :name="`ticket-${product.id}`"
                  :value="getProductAmount(product.id)"
                  @change="setProductAmount(product.id, $event.target.value)"
                >
                  <option
                    v-for="amount in Array.from(
                      Array(product.maxPerOrder + 1).keys()
                    )"
                  >
                    {{ amount }}
                  </option>
                </select>
              </td>
              <td class="product-price large">
                <span v-if="product.soldOut" class="red">Sold out</span>
                <span v-else> {{ product.currency }} {{ product.price }} </span>
              </td>
            </tr>
            <div v-if="product.description" class="d-flex flex-row mb-3">
              <i class="fa-solid fa-circle-info mt-1 ps-3 me-2"></i>
              <span
                v-if="product.description"
                class="small italic d-block margin-0 col-6"
              >
                {{ product.description }}</span
              >
            </div>
          </div>
          <p
            v-if="product.countries.length > 0"
            class="small margin-0 ps-3 pt-2 italic"
          >
            * Local rates are available for organizations with headquarters in
            <span
              v-for="(country, index) in product.countries"
              class="inline small"
              >{{ country
              }}<span
                v-if="index == product.countries.length - 2"
                class="small"
              >
                or </span
              ><span
                v-else-if="index == product.countries.length - 1"
                class="small"
                >.</span
              ><span v-else class="small">, </span></span
            >
          </p>
        </template>

        <tbody v-if="discountTotals.length > 0 && promotionCodeIsApplied">
          <tr class="product-row">
            <td class="product-title large">
              <span class="large bold blue-light">Subtotal</span>
            </td>
            <td class="product-amount large"></td>
            <td class="product-price large">
              {{ totals.currency }} {{ totals.subtotal }}
            </td>
          </tr>

          <tr class="product-row" v-for="discountTotal in discountTotals">
            <td class="product-title">
              <span class="large bold green">Promotion code: </span>
              {{ discountTotal.promotionCode }}<br />
              <span class="small green"
                >{{ discountTotal.amount }}x {{ discountTotal.name }} -
                {{ discountTotal.discountPercentage }}% discount</span
              >
            </td>
            <td class="product-amount large"></td>
            <td class="product-price large">
              -{{ discountTotal.currency }}{{ discountTotal.subtotalAbsolute }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-between mb-3">
        <p class="display-4 bold blue-light ms-3">Total</p>
        <p class="display-4 bold blue-light me-3">
          {{ totals.currency }} {{ totals.total }}<br />
          <span class="float-end blue-light">excl. VAT</span>
        </p>
      </div>

      <div class="row">
        <div class="col-6 mb-4">
          <div class="d-block position-relative">
            <span
              class="blue-light large ps-2 position-absolute end-0 mt-1"
              v-if="showRemovePromotionCode"
              v-on:click="unApplyPromotionCode"
              ><i class="fa-regular fa-circle-xmark"></i
            ></span>
            <input
              class="small gray ms-3"
              type="text"
              v-model="tickets.promotionCode"
              placeholder="Promotion Code"
              @change="promotionCodeChanged = true"
              @input="promotionCodeChanged = true"
            />
            <FormErrors
              class="ms-3"
              :errors="getError('tickets', 0, 'promotionCode')"
            ></FormErrors>
            <p
              class="small green ms-3 pt-1"
              v-if="promotionCodeIsApplied && !promotionCodeChanged"
            >
              <i
                class="fa-solid fa-check align-left"
                title="Promotion codes applied"
              ></i
              >Promotion code applied
            </p>
            <a
              class="small green ms-3 ps-2"
              v-if="showApplyPromotionCode"
              v-on:click="applyPromotionCode"
              >Apply</a
            >
            <FormErrors
              :errors="getError('tickets', 0, '__all__')"
            ></FormErrors>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <button
            class="green medium float-end me-2 mb-5"
            @click.prevent="$emit('done-tickets')"
            :disabled="ticketsButtonDisabled"
          >
            Continue <i class="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormErrors from '../../components/FormFieldErrors.vue'

export default {
  name: 'TicketSelection',
  components: { FormErrors },
  props: {
    event: Object,
    tickets: Object,
    products: Array,
    totals: Object,
    discountTotals: Object,
    currentStepLabel: String,
    getError: Function,
    getStepErrors: Function,
    urls: Object,
  },
  data() {
    return {
      promotionCodeChanged: false,
    }
  },
  emits: ['done-tickets', 'validate', 'delayed-validate'],

  computed: {
    ticketsButtonDisabled() {
      // on first load, no server-side errors are available
      // so check product selection client side
      if (this.tickets.selection.length === 0) {
        return true
      }
      return this.getStepErrors('tickets').length > 0
    },
    showApplyPromotionCode() {
      return this.promotionCodeChanged && this.tickets.promotionCode.length > 0
    },
    showRemovePromotionCode() {
      return this.tickets.promotionCode.length > 0
    },
    promotionCodeIsApplied() {
      return (
        !this.promotionCodeChanged &&
        this.tickets.promotionCode.length > 0 &&
        this.getError('tickets', 0, 'promotionCode').length === 0
      )
    },
  },
  methods: {
    availableTickets() {
      return this.products.filter((product) => {
        return product.type === 'ticket'
      })
    },
    ticket() {
      return this.products.find((product) => {
        return product.type === 'ticket'
      })
    },
    availableAddons() {
      return this.products.filter((product) => {
        return product.type === 'add_on'
      })
    },
    getProductAmount(productId) {
      let item = this.tickets.selection.find(
        (product) => product.id === productId
      )
      if (item) {
        return item.amount
      }
      return 0
    },
    setProductAmount(productId, amount) {
      let item = this.tickets.selection.find(
        (product) => product.id === productId
      )
      if (item) {
        item.amount = amount
      } else {
        this.tickets.selection.push({ id: productId, amount: amount })
      }
      this.$emit('validate', 0, ['__all__'])
    },
    applyPromotionCode() {
      this.$emit('validate', 0, ['promotionCode'])
      this.promotionCodeChanged = false
    },
    unApplyPromotionCode() {
      this.tickets.promotionCode = ''
      this.$emit('validate', 0, ['promotionCode'])
    },
  },
}
</script>

<style scoped></style>
