import { createApp } from 'vue'
import SendConnectionRequestModal from './SendConnectionRequestModal.vue'

export default class {
  function

  constructor() {
    $('.send-connection-request').on('click', (event) => {
      // $(this).data('memberId') should also work, but for some reason that resolves to undefined.
      // So I'm getting the data attributes from event.target instead.
      let element = $(event.target)
      const memberId = element.data('memberId')
      const memberName = element.data('memberName')
      const memberOrganization = element.data('memberOrganization')

      this.initConnectionRequest(
        memberId,
        memberName,
        memberOrganization,
        `#connection-request-${memberId}`
      )
    })
  }

  initConnectionRequest(memberId, memberName, memberOrganization, component) {
    let app = undefined

    app = createApp(
      {
        template: '<SendConnectionRequestModal/>',
        components: { SendConnectionRequestModal },
      },
      {
        onClose: () => {
          // Close the modal.
          app.unmount()
          app = undefined
        },
        memberId: memberId,
        memberName: memberName,
        memberOrganization: memberOrganization,
      }
    )

    app.mount(component)
  }
}
