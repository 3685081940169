import { ref } from 'vue'

export default function useValidation() {
  const errors = ref([])

  function handleValidationResponse(validation, currentStep) {
    if (!validation.target) {
      console.log('no validation target, full validation', validation.errors)
      errors.value = validation.errors.map((error) => {
        error.show = true
        return error
      })
      return
    }
    // remove previous errors for this target
    errors.value = errors.value.filter(
      (error) =>
        !(
          error.step === currentStep &&
          error.index === validation.target.index &&
          validation.target.fields.includes(error.field)
        )
    )
    // add new errors for this target
    validation.errors.forEach((error) => {
      error.show =
        error.step === currentStep &&
        error.index === validation.target.index &&
        validation.target.fields.includes(error.field)
      errors.value.push(error)
    })
  }
  function getError(step, index, field) {
    let error = errors.value.find(
      (error) =>
        error.show &&
        error.step === step &&
        error.index === index &&
        error.field === field
    )
    if (error) {
      return error.messages
    }
    return []
  }

  function getStepErrors(step) {
    return errors.value.filter((error) => error.step === step)
  }

  return { errors, handleValidationResponse, getError, getStepErrors }
}
