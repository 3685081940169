<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue">What are your priority tags?</span>
  </header>
  <main class="s-modal-body">
    <p class="blue">
      These can be sourced from the extensive list of existing tags
      <b
        >(higher chances of inclusion in filters and connection to other
        organizations and resources)</b
      >
      or custom-created.
    </p>
    <p class="blue">You can choose up to 5 priority tags</p>
    <tag-input
      v-model="tags"
      :tag-options="tagOptions"
      max="5"
      ref="tagInput"
    ></tag-input>
    <p class="display-9 align-right">{{ tags.length }} / 5</p>
  </main>

  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
import TagInput from '../components/TagInput.vue'
import { getAllTags } from '../api'

export default {
  name: 'PriorityTagDialog',
  inheritAttrs: false,
  components: { TagInput },
  emits: ['close', 'saved'],
  props: ['organization'],
  data() {
    return {
      tags: [],
      tagOptions: [],
      dirty: false,
    }
  },
  async mounted() {
    const allTags = await getAllTags()
    this.tagOptions = allTags.map((tag) => {
      return {
        value: tag,
        label: tag,
      }
    })

    this.tags = [...this.organization.priority_tags]

    this.$refs.tagInput.focus()

    this.$watch(
      'tags',
      () => {
        this.dirty = true
      },
      { deep: true }
    )
  },
  methods: {
    async save() {
      this.$emit('saved', { priority_tags: this.tags })
    },
  },
}
</script>
