<template>
  <img ref="img" :src="src" />
</template>

<script>
import Cropper from 'cropperjs'

export default {
  name: 'ImageCropper',
  props: {
    src: String,
  },
  emits: ['change'],
  data() {
    return {
      cropper: null,
    }
  },
  mounted() {
    this.cropper = new Cropper(this.$refs.img, {
      viewMode: 0,
      aspectRatio: 400 / 240,
      minCropBoxWidth: 40,
      minCropBoxHeight: 24,
      guides: false,
      autoCropArea: 1,
    })
    this.$refs.img.addEventListener('cropstart', () => {
      this.$emit('change')
    })
  },
}
</script>
