<template>
  <button
    v-if="!loading"
    :class="this.class || 'orange small'"
    :disabled="this.disabled"
  >
    <slot />
  </button>
  <button v-else disabled :class="this.class || 'orange small'">
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ButtonWithLoadingIcon',
  props: ['loading', 'disabled', 'organizationDetails', 'class'],
  events: ['click'],
}
</script>

<style scoped></style>
