import { createApp } from 'vue'
import Registration from './registration/Registration.vue'

export default class {
  constructor() {
    if (document.getElementById('vue-registration')) {
      this.initVueApp()
    }
    this.initToggleSignUp()
    this.initNewRegistration()
    this.initTutorial()
    this.initDelay()
  }

  initVueApp() {
    createApp({
      template: '<Registration/>',
      components: {
        Registration,
      },
    }).mount('#vue-registration')
  }

  initToggleSignUp() {
    $('.toggle-sign-up').on('click', () => {
      $('.login-container').addClass('d-none d-lg-block')
      $('.register-container').removeClass('d-none d-lg-block')
    })
  }

  initNewRegistration() {
    $(document).on('click', '#new-registration', function (evt) {
      localStorage.setItem('tutorial', 'yes')
    })
  }

  initTutorial() {
    if (window.location.pathname == '/account/dashboard/') {
      if (localStorage.getItem('tutorial') != null) {
        $('#tutorial-welcome').show()
        localStorage.removeItem('tutorial')
      }
    }

    $('#tutorial-1').on('click', () => {
      $('#tutorial-welcome').hide()
      $('#tutorial-bookmark').show()
      $('#bookmark-tile').css('zIndex', '9999')
    })

    $('#tutorial-2').on('click', () => {
      $('#tutorial-bookmark').hide()
      $('#bookmark-tile').css('zIndex', '1')
      if ($('#tutorial-organization')[0]) {
        $('#tutorial-organization').show()
        $('#organization-tile').css('zIndex', '9999')
      } else {
        $('#tutorial-personal').show()
        $('#personal-tile').css('zIndex', '9999')
      }
    })

    $('#tutorial-3').on('click', () => {
      $('#tutorial-organization').hide()
      $('#organization-tile').css('zIndex', '1')
      $('#tutorial-personal').show()
      $('#personal-tile').css('zIndex', '9999')
    })

    $('.tutorial-close').on('click', () => {
      $('#tutorial-welcome').hide()
      $('#tutorial-bookmark').hide()
      $('#tutorial-organization').hide()
      $('#tutorial-personal').hide()
      $('#bookmark-tile').css('zIndex', '1')
      $('#organization-tile').css('zIndex', '1')
      $('#personal-tile').css('zIndex', '1')
    })
  }

  initDelay() {
    $('#resend-delay').ready(() => {
      $('#resend-delay').delay(30000).fadeIn(20)
    })
  }
}
