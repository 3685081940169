<template>
  <section
    class="s-file-upload"
    :class="{ 'drag-target': dragTargetActive }"
    @dragover.prevent="onDragOver"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
  >
    <input
      type="file"
      @change.prevent="onFileUpload"
      accept=".jpg,.jpeg,.png,.webp"
    />
  </section>
</template>

<script>
export default {
  name: 'ImageUpload',
  emits: ['change'],
  data() {
    return {
      dragTargetActive: false,
    }
  },
  methods: {
    onFileUpload(event) {
      const file = event.target.files[0]

      const fileReader = new FileReader()
      fileReader.onloadend = (event) => {
        this.$emit('change', event.target.result)
      }
      fileReader.readAsDataURL(file)
    },
    onDragEnter() {
      this.dragTargetActive = true
    },
    onDragLeave() {
      this.dragTargetActive = false
    },
    onDragOver() {
      this.dragTargetActive = true
    },
  },
}
</script>
