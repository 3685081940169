<template>
  <div
    v-if="showStepsMenu"
    style="border: 1px red dashed; margin-top: 50px; padding: 30px"
  >
    <p class="large">For development</p>
    <nav>
      <ol>
        <li
          v-for="step of steps"
          :style="step.name === currentStep ? 'font-weight: bold' : ''"
        >
          <div class="step-mark"></div>
          <a v-on:click="goToStep(step.name)">
            {{ step.label }}
          </a>
        </li>
        <li>
          <div class="step-mark"></div>
          <a :href="urls.reset"> Start over </a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'StepsMenu',
  props: {
    showStepsMenu: {
      type: Boolean,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: String,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToStep(stepName) {
      this.$emit('go-to-step', stepName)
    },
  },
}
</script>

<style scoped></style>
