<template>
  <table v-if="summary.productTotals.length" class="product-basket">
    <tr class="product-row" v-for="productTotal in summary.productTotals">
      <td class="product-amount">{{ productTotal.amount }}</td>
      <td class="product-title">
        <span class="bold blue-light">{{ productTotal.category }}:</span>
        {{ productTotal.name }}<br />
        <span class="small blue-light">{{ event.title }}</span>
      </td>
      <td class="product-price large">
        {{ productTotal.currency }} {{ productTotal.subtotal }}
      </td>
    </tr>
    <tr class="product-row" v-for="discountTotal in summary.discountTotals">
      <td class="product-amount">{{ discountTotal.amount }}</td>
      <td class="product-title">
        <span class="bold green">Promotion code: </span>
        {{ discountTotal.promotionCode }}<br />
        <span class="small green"
          >{{ discountTotal.name }} - {{ discountTotal.discountPercentage }}%
          discount</span
        >
      </td>
      <td class="product-price large">
        -{{ discountTotal.currency }} {{ discountTotal.subtotalAbsolute }}
      </td>
    </tr>
  </table>

  <p v-else class="large mb-5">
    Once selected, any add-ons that were not part of your contracted ticket
    package will appear here. The total amount listed can be paid directly by
    credit card or will otherwise be invoiced.
  </p>

  <div class="d-flex justify-content-between mb-3">
    <p class="display-4 bold blue-light">Total</p>
    <p class="display-4 bold blue-light me-3">
      {{ summary.totals.currency }} {{ summary.totals.total }}<br />
      <span class="float-end blue-light">excl. VAT</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'OrderSummary',
  props: {
    event: Object,
    type: Object,
    summary: Object,
  },
}
</script>

<style scoped></style>
