import { createApp } from 'vue'
import MessagingHome from './MessagingHome.vue'

export default class {
  constructor() {
    if (document.getElementById('vue-messaging')) {
      this.initVueApp()
    }
  }

  initVueApp() {
    createApp({
      template: '<MessagingHome/>',
      components: {
        MessagingHome,
      },
    }).mount('#vue-messaging')
  }
}
