import Modal from 'bootstrap/js/dist/modal'

export default class {
  constructor() {
    this.init()
  }

  // Bind to bookmark widgets
  init() {
    $('.bookmark-event').on('click', (event) => {
      this.toggleBookmark(
        'EVENT',
        $(event.currentTarget).data('id'),
        $(event.currentTarget)
      )
      return false
    })

    $('body').on('click', '.bookmark-article', (event) => {
      this.toggleBookmark(
        'ARTICLE',
        $(event.currentTarget).data('id'),
        $(event.currentTarget)
      )
      return false
    })

    $('body').on('click', '.bookmark-organization', (event) => {
      this.toggleBookmark(
        'ORGANIZATION',
        $(event.currentTarget).data('id'),
        $(event.currentTarget)
      )
      return false
    })
  }

  toggleBookmark(type, id, target) {
    $.ajax({
      url: `/account/bookmark/`,
      type: 'POST',
      data: JSON.stringify({ type: type, id: id }),
      headers: {
        'X-CSRFToken': $('meta[name="csrf-token"]').attr('content'),
      },
      success: (result) => {
        // Toggle appearance of bookmark widget
        if (result.bookmarked) {
          target.attr('title', 'Remove bookmark')
          target.addClass('active')
          target
            .find('.icon')
            .removeClass('fa-bookmark-o')
            .addClass('fa-bookmark')
        } else {
          target.attr('title', 'Add bookmark')
          target.removeClass('active')
          target
            .find('.icon')
            .removeClass('fa-bookmark')
            .addClass('fa-bookmark-o')
        }
      },
      error: (result) => {
        if (result.status === 401) {
          const modal = new Modal(document.getElementById('login-warning'))
          modal.show()
        }
      },
    })
  }
}
