export default {
  productTotals: [
    {
      id: 0,
      category: '',
      name: '',
      amount: 0,
      currency: '€',
      subtotal: 0,
    },
  ],
  discountTotals: [
    {
      promotionCode: '',
      discount: 0,
      name: '',
      amount: 0,
      currency: '€',
      subtotal: 0,
    },
  ],
  totals: {
    currency: '€',
    subtotal: 0,
    discount: 0,
    total: '0.00',
    vat: 0,
    totalInclVat: 0,
  },
}
