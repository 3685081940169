<template>
  <label for="optin_marketing"
    ><input
      id="optin_marketing"
      type="checkbox"
      v-model="form.optin_marketing"
    /><span
      >Send me information and offers from Solarplaza. No strings attached, you
      can unsubscribe anytime. For more information review our
      <a :href="urls.privacyPolicy" target="_blank">Privacy Policy</a></span
    ></label
  >

  <label for="agreed_terms"
    ><input
      id="agreed_terms"
      type="checkbox"
      v-model="form.agreed_terms"
    /><span
      >Creating an account means you're okay with our
      <a :href="urls.privacyPolicy" target="_blank">Privacy Policy</a>
      and the
      <a :href="urls.termsAndConditions" target="_blank">Terms and Conditions</a
      >.</span
    ></label
  >
</template>

<script>
export default {
  name: 'TermsAndConditions',
  props: {
    form: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
  },
}
</script>
