<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue">Geographies active in</span>
  </header>
  <main class="geographies-main">
    <p class="blue">
      This can be done on a broad regional level or on a more specific country
      level. Only select geographies where your organization has proven track
      record or is actively promoting its services.
    </p>
    <section>
      <label>Continents</label>
      <tag-input
        v-model="model.continents"
        :tag-options="continentOptions"
        :allow-new="false"
        ref="regionInput"
      ></tag-input>
    </section>
    <section>
      <label>Regions</label>
      <tag-input
        v-model="model.regions"
        :tag-options="regionOptions"
        :allow-new="false"
        ref="regionInput"
      ></tag-input>
    </section>
    <section>
      <label>Countries</label>
      <tag-input
        v-model="model.countries"
        :tag-options="countryOptions"
        :allow-new="false"
      ></tag-input>
    </section>
    <section>
      <label>Sub-countries</label>
      <tag-input
        v-model="model.subCountries"
        :tag-options="subCountryOptions"
        :allow-new="false"
      ></tag-input>
    </section>
  </main>
  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
import TagInput from '../components/TagInput.vue'
import { getGeographies } from '../api'

export default {
  name: 'GeographyDialog',
  components: { TagInput },
  emits: ['close', 'saved'],
  props: ['organization'],
  inheritAttrs: false,
  data() {
    return {
      model: {
        regions: [],
        countries: [],
        subCountries: [],
      },
      dirty: false,
      continentOptions: [],
      regionOptions: [],
      countryOptions: [],
      subCountryOptions: [],
    }
  },
  async mounted() {
    this.continentOptions = await getGeographies('continental')
    this.regionOptions = await getGeographies('sub-continental')
    this.countryOptions = await getGeographies('country')
    this.subCountryOptions = await getGeographies('sub-country')

    this.model.continents = this.organization.geographies.filter(
      (geography) => geography.level === 'continental'
    )
    this.model.regions = this.organization.geographies.filter(
      (geography) => geography.level === 'sub-continental'
    )
    this.model.countries = this.organization.geographies.filter(
      (geography) => geography.level === 'country'
    )
    this.model.subCountries = this.organization.geographies.filter(
      (geography) => geography.level === 'sub-country'
    )

    this.$refs.regionInput.focus()

    this.$watch(
      'model',
      () => {
        this.dirty = true
      },
      { deep: true }
    )
  },
  methods: {
    async save() {
      this.$emit('saved', {
        geographies: [
          ...this.model.continents,
          ...this.model.regions,
          ...this.model.countries,
          ...this.model.subCountries,
        ],
      })
    },
  },
}
</script>
<style scoped lang="scss">
.geographies-main {
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>
