<template>
  <div class="tag-container" @click="focus()" ref="container">
    <span v-for="(tag, index) in modelValue" class="tag-wrapper selected-tag"
      >{{ tag.label }} <i class="fas fa-times" @click="remove(index)"></i
    ></span>
    <span
      class="tag-wrapper"
      :class="{ 'selected-tag': displayAsTag }"
      ref="wrapper"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
    >
      <span
        role="textbox"
        contenteditable
        style="min-width: 16px"
        @input="filter"
        ref="query"
        @keydown.enter.prevent="addNewTag"
        @keydown.esc="escape"
        v-if="max === 0 || modelValue.length < max"
      ></span>
      <template v-if="allowNew">
        <i
          class="fas fa-plus icon-button"
          @click="addNewTag"
          v-if="displayAsTag"
        ></i>
      </template>
    </span>
  </div>
  <section
    class="click-catch-area"
    v-if="displayResults"
    @click="clear"
  ></section>
  <ul class="tag-input-search-results" v-if="displayResults" :style="listStyle">
    <li
      v-for="(tag, index) in filteredTags"
      @click="add(tag)"
      :class="{ selected: index === selectedIndex }"
    >
      <span v-html="tag.highlight"></span>
    </li>
  </ul>
</template>

<script>
import { isEmpty } from 'lodash-es'

export default {
  // inheritAttrs: false,
  name: 'TagInput',
  props: {
    modelValue: {
      type: Array,
      default: [],
    },
    tagOptions: {
      type: Array,
      default: [],
    },
    max: {
      default: 0,
    },
    allowNew: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      filteredTags: [],
      displayResults: false,
      displayAsTag: false,
      listStyle: '',
      selectedIndex: 0,
    }
  },
  mounted() {
    this.filteredTags = [...this.tagOptions]
    this.listStyle = `width: ${this.$refs.container.clientWidth}px`
  },
  watch: {
    selected: function (newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
  methods: {
    escape() {
      if (this.filteredTags.length > 0) {
        this.filteredTags = []
      } else {
      }
    },
    focus() {
      if (this.$refs.query) {
        this.$refs.query.focus()
      }
    },
    filter(event) {
      const query = event.target.innerHTML.toLowerCase()
      this.filteredTags = this.tagOptions
        .filter((tag) => tag.label.toLowerCase().includes(query))
        .filter((tag) => !this.modelValue.includes(tag.value))
        .map((tag) => {
          return {
            value: tag.value,
            label: tag.label,
            highlight: this.highlight(tag.label, query),
          }
        })
      this.displayResults = this.filteredTags.length > 0
      this.displayAsTag = query && query.length > 0

      const chars = query ? query.length : 1
      this.style = `width: ${chars * 16}px`
    },
    add(tag) {
      this.modelValue.push({ value: tag.value, label: tag.label })
      this.clear()
    },
    clear() {
      this.filteredTags = []
      this.$refs.query.innerText = ''
      this.displayResults = false
      this.displayAsTag = false
      this.focus()
    },
    addNewTag() {
      if (this.allowNew) {
        if (
          !isEmpty(this.$refs.query.innerText) &&
          this.$refs.query.innerText.length > 2
        ) {
          this.modelValue.push({
            value: this.$refs.query.innerText,
            label: this.$refs.query.innerText,
          })
          this.clear()
        }
      } else {
        this.add(this.filteredTags[this.selectedIndex])
      }
    },
    remove(index) {
      this.modelValue.splice(index, 1)
    },
    highlight(source, query) {
      const groups = source.match(new RegExp(`(.*)(${query})(.*)`, 'i'))
      return groups
        ? `${groups[1]}<span class="display-5 blue bold">${groups[2]}</span>${groups[3]}`
        : ''
    },
    onArrowDown() {
      if (this.selectedIndex < this.filteredTags.length - 2) {
        this.selectedIndex = this.selectedIndex + 1
      }
    },
    onArrowUp() {
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1
      }
    },
  },
}
</script>

<style scoped></style>
