import { createApp } from 'vue'
import { plugin, defaultConfig } from '@formkit/vue'
import { createI18n } from 'vue-i18n'
import EventAttendeeStatusDialog from './EventAttendeeStatusDialog.vue'
import { messages } from '../../i18n/messages'

const i18n = createI18n({
  locale: 'en',
  messages: messages,
})

export const loadEventAttendeeStatusDialog = (event) => {
  createApp(
    {
      template: '<EventAttendeeStatusDialog/>',
      components: { EventAttendeeStatusDialog },
    },
    {
      event: event,
    }
  )
    .use(plugin, defaultConfig)
    .use(i18n)
    .mount('#update-event-attendee-status')
}
