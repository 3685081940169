<template>
  <div v-if="errors" class="field-errors">
    <ul>
      <li v-for="error in errors">
        <span v-if="error.code === 'no_public'"
          ><span v-html="error.message"></span>
          <i
            class="fa-regular fa-circle-question blue-light click"
            data-bs-toggle="collapse"
            data-bs-target="#accountinfo"
            aria-expanded="false"
            aria-controls="accountinfo"
          ></i>
        </span>
        <span v-else v-html="error.message"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FormErrors',
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped></style>
