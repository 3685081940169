import { createApp } from 'vue'
import AddAddOns from './add-add-ons/AddAddOns.vue'
import FillSlots from './fill-slots/FillSlots.vue'
import Tickets from './tickets/Tickets.vue'

export default class {
  constructor() {
    this.initProgram()
    this.initFaq()
    this.initRegistration()
    this.initAddAddOns()
    this.initFillSlots()
  }

  initProgram() {
    $('.session-expand').on('click', (event) => {
      let $expander = $(event.currentTarget)
      $('#topics' + $expander.data('session-id')).toggle()
    })
  }

  initFaq() {
    $('.question-box').on('click', (event) => {
      let $expander = $(event.currentTarget)
      $('#answer' + $expander.data('question-id')).toggle()
    })
  }

  initRegistration() {
    if (document.getElementById('vue-tickets')) {
      createApp({
        template: '<Tickets/>',
        components: {
          Tickets,
        },
      }).mount('#vue-tickets')
    }
  }
  initAddAddOns() {
    if (document.getElementById('vue-add-add-ons')) {
      createApp({
        template: '<AddAddOns/>',
        components: {
          AddAddOns,
        },
      }).mount('#vue-add-add-ons')
    }
  }

  initFillSlots() {
    if (document.getElementById('vue-fill-slots')) {
      createApp({
        template: '<FillSlots/>',
        components: {
          FillSlots,
        },
      }).mount('#vue-fill-slots')
    }
  }
}
