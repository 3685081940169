<template>
  <div class="row">
    <div class="col-md-6 billing-information">
      <h3 class="display-3 bold blue-light margin-2">Billing information</h3>

      <div class="row">
        <div class="col-md-6">
          <label for="firstName">First name:</label>
          <input
            type="text"
            id="firstName"
            v-model="billing.firstName"
            maxlength="150"
            @change="$emit('validate', 0, ['firstName'])"
            @input="$emit('delayed-validate', 0, ['firstName'])"
            @focusout="$emit('validate', 0, ['firstName'])"
          />

          <FormErrors
            :errors="getError('billing', 0, 'firstName')"
          ></FormErrors>
        </div>

        <div class="col-md-6">
          <label for="lastName">Last name:</label>
          <input
            type="text"
            id="lastName"
            v-model="billing.lastName"
            maxlength="150"
            @change="$emit('validate', 0, ['lastName'])"
            @input="$emit('delayed-validate', 0, ['lastName'])"
            @focusout="$emit('validate', 0, ['lastName'])"
          />

          <FormErrors :errors="getError('billing', 0, 'lastName')"></FormErrors>
        </div>
      </div>

      <label for="email">Email:</label>
      <input
        type="email"
        id="email"
        v-model="billing.email"
        maxlength="254"
        @change="$emit('validate', 0, ['email'])"
        @input="$emit('delayed-validate', 0, ['email'])"
        @focusout="$emit('validate', 0, ['email'])"
      />
      <FormErrors :errors="getError('billing', 0, 'email')"></FormErrors>

      <hr />

      <label for="organization">Organization name:</label>
      <input
        type="text"
        id="organization"
        v-model="billing.organization"
        maxlength="254"
        @change="$emit('validate', 0, ['organization'])"
        @input="$emit('delayed-validate', 0, ['organization'])"
        @focusout="$emit('validate', 0, ['organization'])"
      />
      <FormErrors :errors="getError('billing', 0, 'organization')"></FormErrors>

      <label for="country">Country:</label>
      <select
        id="country"
        v-model="billing.country"
        @change="$emit('validate', 0, ['country', 'vatNumber'])"
        @focusout="$emit('validate', 0, ['country', 'vatNumber'])"
      >
        <option value="" disabled selected>Select your country</option>
        <option v-for="country in countries" :value="country.id">
          {{ country.name }}
        </option>
      </select>
      <FormErrors :errors="getError('billing', 0, 'country')"></FormErrors>

      <label for="addressLine1">Billing Address:</label>
      <input
        type="text"
        id="addressLine1"
        v-model="billing.addressLine1"
        maxlength="254"
        @change="$emit('validate', 0, ['addressLine1'])"
        @input="$emit('delayed-validate', 0, ['addressLine1'])"
        @focusout="$emit('validate', 0, ['addressLine1'])"
      />
      <FormErrors :errors="getError('billing', 0, 'addressLine1')"></FormErrors>

      <div class="row">
        <div class="col-md-6">
          <label for="postalCode">ZIP Code:</label>
          <input
            type="text"
            id="postalCode"
            v-model="billing.postalCode"
            maxlength="254"
            @change="$emit('validate', 0, ['postalCode'])"
            @input="$emit('delayed-validate', 0, ['postalCode'])"
            @focusout="$emit('validate', 0, ['postalCode'])"
          />
          <FormErrors
            :errors="getError('billing', 0, 'postalCode')"
          ></FormErrors>
        </div>

        <div class="col-md-6">
          <label for="city">City:</label>
          <input
            type="text"
            id="city"
            v-model="billing.city"
            maxlength="254"
            @change="$emit('validate', 0, ['city'])"
            @input="$emit('delayed-validate', 0, ['city'])"
            @focusout="$emit('validate', 0, ['city'])"
          />
          <FormErrors :errors="getError('billing', 0, 'city')"></FormErrors>
        </div>
      </div>

      <template v-if="!hiddenFields.includes('state')">
        <label for="state">State:</label>
        <select
          id="state"
          v-model="billing.state"
          @change="$emit('validate', 0, ['state'])"
          @focusout="$emit('validate', 0, ['state'])"
        >
          <option value="" disabled selected>Select your state</option>
          <option v-for="state in states" :value="state.code">
            {{ state.name }}
          </option>
        </select>
        <FormErrors :errors="getError('billing', 0, 'state')"></FormErrors>
      </template>

      <template v-if="!hiddenFields.includes('vatNumber')">
        <label for="vatNumber">Vat Number:</label>
        <input
          type="text"
          id="vatNumber"
          v-model="billing.vatNumber"
          maxlength="254"
          @change="$emit('validate', 0, ['vatNumber'])"
          @input="$emit('delayed-validate', 0, ['vatNumber'])"
          @focusout="$emit('validate', 0, ['vatNumber'])"
        />
        <FormErrors :errors="getError('billing', 0, 'vatNumber')"></FormErrors>
      </template>
      <label for="consent-data-processing" class="mt-3">
        <input
          id="consent-data-processing"
          type="checkbox"
          v-model="billing.consentDataProcessing"
        /><span
          >I consent to Solarplaza handling my billing for event services and
          confirm I've read the
          <a :href="urls.privacyPolicy" target="_blank">Privacy Policy</a>.
        </span>
      </label>

      <label for="consent-terms-and-conditions" class="mb-3">
        <input
          id="consent-terms-and-conditions"
          type="checkbox"
          v-model="billing.consentTermsAndConditions"
        /><span
          >I agree to the Solarplaza
          <a :href="urls.termsAndConditionsUrl" target="_blank">
            Terms and Conditions
          </a>
        </span>
      </label>

      <FormErrors :errors="getError('billing', 0, '__all__')"></FormErrors>

      <button
        class="green medium"
        @click.prevent="$emit('done-billing')"
        :disabled="billingButtonDisabled"
      >
        Continue <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>

    <div class="col-md-1"></div>

    <div class="d-none d-md-block col-md-5 order-summary">
      <h3 class="display-3 margin-2 bold blue-light">Your order</h3>

      <OrderSummary :event="event" :summary="summary"></OrderSummary>
    </div>
  </div>
</template>

<script>
import FormErrors from '../../components/FormFieldErrors.vue'
import OrderSummary from './OrderSummary.vue'

export default {
  name: 'Billing',
  components: { FormErrors, OrderSummary },
  props: {
    event: Object,
    currentStep: String,
    urls: Object,
    summary: Object,
    countries: Array,
    states: Array,
    billing: Object,
    errors: Object,
    hiddenFields: Array,
    getError: Function,
  },
  emits: ['done-billing', 'validate', 'delayed-validate'],
  computed: {
    billingButtonDisabled() {
      if (
        !(
          this.billing.consentDataProcessing &&
          this.billing.consentTermsAndConditions
        )
      ) {
        return true
      }
      return false
    },
  },
  methods: {
    validate(fields) {
      this.$emit('validate', fields)
    },
    delayedValidate(fields) {
      this.$emit('delayed-validate', fields)
    },
  },
}
</script>

<style scoped></style>
<script setup></script>
