<template>
  <section class="s-flex-horizontal">
    <section>
      <label>Available {{ label }}</label>
      <select multiple style="height: 480px; width: 480px" ref="selectLeft">
        <option v-for="option in availableOptions" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </section>
    <section class="s-flex-vertical">
      <i class="fas fa-arrow-right icon-button" @click="add()"></i>
      <i class="fas fa-arrow-left icon-button" @click="remove()"></i>
    </section>
    <section>
      <label>Selected {{ label }}</label>
      <select multiple style="height: 480px; width: 480px" ref="selectRight">
        <option v-for="option in selectedOptions" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </section>
  </section>
</template>

<script>
import { orderBy } from 'lodash-es'

function selectedOptions(select) {
  const result = []
  for (let i = 0; i < select.options.length; i++) {
    if (select.options.item(i).selected) {
      result.push({
        value: parseInt(select.options.item(i).value),
        label: select.options.item(i).innerText,
      })
    }
  }
  return result
}

function selectedOptionValues(select) {
  const result = []
  for (let i = 0; i < select.options.length; i++) {
    if (select.options.item(i).selected) {
      result.push(parseInt(select.options.item(i).value))
    }
  }
  return result
}

function deselectOptions(select) {
  for (let i = 0; i < select.options.length; i++) {
    select.options.item(i).selected = false
  }
}
export default {
  name: 'DualPaneSelect',
  props: {
    modelValue: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedOptions: [],
      availableOptions: [],
    }
  },
  mounted() {
    this.selectedOptions = this.modelValue
    this.availableOptions = this.options
  },
  watch: {
    options: function () {
      this.updateAvailableOptions()
    },
  },
  methods: {
    add() {
      const selected = selectedOptions(this.$refs.selectLeft)
      selected.forEach((item) => {
        this.selectedOptions.push(item)
      })

      this.selectedOptions = orderBy(this.selectedOptions, 'label')

      this.updateAvailableOptions()
      deselectOptions(this.$refs.selectLeft)

      this.$emit('update:modelValue', this.selectedOptions)
    },
    remove() {
      const selectedValues = selectedOptionValues(this.$refs.selectRight)
      this.selectedOptions = this.selectedOptions.filter(
        (element) => !selectedValues.includes(element.value)
      )

      this.updateAvailableOptions()
      deselectOptions(this.$refs.selectRight)

      this.$emit('update:modelValue', this.selectedOptions)
    },
    updateAvailableOptions() {
      const selectedIds = this.selectedOptions.map((element) => element.value)
      this.availableOptions = this.options.filter(
        (element) => !selectedIds.includes(element.value)
      )
    },
  },
}
</script>

<style scoped></style>
