<template>
  <teleport to="body">
    <section
      class="s-modal-backdrop"
      @click="$emit('close')"
      @scroll.prevent
      @wheel.prevent
      @touchmove.prevent
    >
      <section class="s-popup-container">
        <article
          class="s-modal-popup"
          @keyup.esc.prevent="$emit('close')"
          @click.stop
          :style="style"
        >
          <slot></slot>
          <button
            class="btn btn-link close-button"
            style="position: absolute; top: 20px; right: 20px"
            @click="$emit('close')"
          >
            <i class="fas fa-times blue"></i>
          </button>
        </article>
      </section>
    </section>
  </teleport>
</template>

<script>
export default {
  name: 'ModalContainer',
  props: ['title'],
  data() {
    return {}
  },
}
</script>
