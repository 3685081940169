<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue"
      >What is your organization's main application?</span
    >
  </header>
  <main class="s-modal-body">
    <p class="blue">
      On the highest level, we differentiate between utility-scale, commercial &
      industrial, residential and off-grid, but on a detailed level this field
      also includes specific and niche applications.
    </p>
    <section class="tag-container" style="border: none">
      <span class="tag orange" v-for="application in applications">
        <input
          type="checkbox"
          :value="application.value"
          v-model="selectedApplications"
        />{{ application.label }}
      </span>
    </section>

    <!--    <span class="display-6 bold blue">And what detailed application?</span>-->
    <!--    <section class="tag-container" style="border: none">-->
    <!--        <span class="tag orange" v-for="technology in technologies">-->
    <!--          <input type="checkbox" :value="technology.value"-->
    <!--                 v-model="model.technologies">{{ technology.label }}-->
    <!--        </span>-->
    <!--    </section>-->
  </main>
  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
export default {
  name: 'ApplicationDialog',
  emits: ['close', 'saved'],
  props: ['organization', 'options'],
  inheritAttrs: false,
  data() {
    return {
      selectedApplications: [],
      applications: [],
      model: undefined,
      dirty: false,
    }
  },
  async mounted() {
    this.applications = this.options.applications
    this.selectedApplications = this.organization.applications.map(
      (item) => item.value
    )

    this.$watch(
      'selectedApplications',
      () => {
        this.dirty = true
      },
      { deep: true }
    )
  },
  methods: {
    async save() {
      this.$emit('saved', {
        applications: this.selectedApplications.map((item) => {
          return {
            value: item,
            label: this.applications.find(
              (application) => application.value === item
            ).label,
          }
        }),
      })
    },
  },
}
</script>
