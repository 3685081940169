import { createApp } from 'vue'
import OrganizationEditor from './OrganizationEditor.vue'
import { plugin, defaultConfig } from '@formkit/vue'
import { messages } from '../i18n/messages'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: 'en',
  messages: messages,
})

export const loadOrganizationEditor = () => {
  createApp({
    template: '<OrganizationEditor/>',
    components: { OrganizationEditor },
  })
    .use(plugin, defaultConfig)
    .use(i18n)
    .mount('#organization-editor')
}
