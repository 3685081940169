<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue"
      >What technology does your organization use?</span
    >
  </header>
  <main class="s-modal-body">
    <section class="tag-container" style="border: none">
      <span class="tag orange" v-for="technology in technologies">
        <input
          type="checkbox"
          :value="technology.value"
          v-model="selectedTechnologies"
        />{{ technology.label }}
      </span>
    </section>
  </main>
  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
export default {
  name: 'TechnologyDialog',
  emits: ['close', 'saved'],
  props: ['organization', 'options'],
  inheritAttrs: false,
  data() {
    return {
      selectedTechnologies: [],
      technologies: [],
      model: undefined,
      dirty: false,
    }
  },
  async mounted() {
    this.technologies = this.options.technologies

    this.selectedTechnologies = this.organization.technologies.map(
      (item) => item.value
    )

    this.$watch(
      'selectedTechnologies',
      () => {
        this.dirty = true
      },
      { deep: true }
    )
  },
  methods: {
    async save() {
      this.$emit('saved', {
        technologies: this.selectedTechnologies.map((item) => {
          return {
            value: item,
            label: this.technologies.find(
              (technology) => technology.value === item
            ).label,
          }
        }),
      })
    },
  },
}
</script>
