<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue"
      >Where are your additional offices located?</span
    >
  </header>
  <main class="s-modal-body">
    <p class="blue">
      Is your organization a multi-national and offering the same set of
      products or services in different markets? Please list your local offices
      here, rather than adding separate organization records for local
      subsidairies.
    </p>
    <section class="add-office-form">
      <section>
        <label>HQ location</label>
        <auto-complete
          v-model="form.location"
          :option-callback="findCountries"
          @selected="$refs.url.focus()"
          ref="location"
        ></auto-complete>

        <label>Website URL</label>
        <input type="url" v-model="form.website_url" ref="url" />
      </section>
      <section class="button-panel">
        <i class="fas fa-plus-circle icon-button" @click="add()"></i>
      </section>
    </section>

    <section
      v-for="(office, index) in model.office_locations"
      class="office-card"
    >
      <section>
        {{ office.label }}<br />
        {{ office.website_url }}
      </section>
      <section class="button-panel">
        <i class="fas fa-trash icon-button" @click="remove(index)"></i>
      </section>
    </section>
  </main>

  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
import AutoComplete from '../components/AutoComplete.vue'

export default {
  name: 'LocalOfficeDialog',
  components: { AutoComplete },
  emits: ['close', 'saved'],
  props: ['organization', 'options'],
  inheritAttrs: false,
  data() {
    return {
      model: {},
      dirty: false,
      countries: [],
      form: {},
    }
  },
  async mounted() {
    this.model.office_locations = [...this.organization.office_locations]

    // Prevent FormKit from messing with our data
    this.countries = this.options.countries.map((country) => {
      return { ...country }
    })

    this.$refs.location.focus()
  },
  methods: {
    add() {
      this.model.office_locations.push({
        geography_id: this.form.location.value,
        label: this.form.location.label,
        website_url: this.form.website_url,
      })
      this.dirty = true
    },
    remove(index) {
      this.model.office_locations.splice(index, 1)
      this.dirty = true
    },
    findCountries(query) {
      return this.options.countries.filter((country) =>
        country.label.toLowerCase().includes(query.toLowerCase())
      )
    },
    async save() {
      this.$emit('saved', {
        office_locations: this.model.office_locations,
      })
    },
  },
}
</script>
<style scoped lang="scss">
.add-office-form {
  display: grid;
  grid-template-columns: auto 40px;
}

.button-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.office-card {
  display: grid;
  grid-template-columns: auto 40px;
  margin-top: 10px;
  border: 2px solid #e9ecef;
  border-radius: 5px;
  padding: 10px;
}
</style>
