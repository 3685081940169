<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue">Edit details</span>
  </header>
  <main class="s-modal-body">
    <FormKit
      type="form"
      v-model="model"
      @submit="save()"
      submit-label="Save"
      :actions="false"
      @change.native="dirty = true"
    >
      <FormKit
        type="text"
        label="Active since year"
        name="active_since_year"
        input-class="narrow"
      />

      <FormKit
        type="select"
        label="HQ location"
        name="country_code"
        placeholder="Please select HQ location"
        :options="countries"
      />

      <FormKit
        type="select"
        label="Employees"
        name="employee_count"
        placeholder="Number of employees"
        :options="employeeRanges"
      />

      <label>Subsidiary of</label>
      <auto-complete
        v-model="model.subsidiary_of"
        :option-callback="findCompanies"
      ></auto-complete>

      <FormKit type="url" label="Website URL" name="website_url" />
      <FormKit type="url" label="LinkedIn URL" name="linkedin_url" />
    </FormKit>
  </main>
  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
import { findCompanies } from '../api'
import AutoComplete from '../components/AutoComplete.vue'

export default {
  name: 'OrganizationDetailsDialog',
  components: { AutoComplete },
  emits: ['close', 'saved'],
  props: ['organization', 'options'],
  inheritAttrs: false,
  data() {
    return {
      model: {},
      dirty: false,
      countries: [],
      employeeRanges: [],
    }
  },
  mounted() {
    // Do this in the next tick otherwise FormKit overwrites the model
    this.$nextTick(() => {
      this.model = {
        active_since_year: this.organization.active_since_year,
        country_code: this.organization.located_in_country.value,
        employee_count: this.organization.employee_count,
        subsidiary_of: this.organization.subsidiary_of,
        website_url: this.organization.website_url,
        linkedin_url: this.organization.linkedin_url,
        change_date: this.organization.change_date,
      }

      // Prevent FormKit from messing with our data
      this.countries = this.options.countries.map((country) => {
        return { ...country }
      })

      this.employeeRanges = this.options.employeeRanges.map((item) => {
        return {
          label: item[1],
          value: item[0],
        }
      })
    })
  },
  computed: {
    // countries() {
    //   return this.options.countries
    // },
  },
  methods: {
    async save() {
      const country = this.options.countries.find(
        (country) => country.value === this.model.country_code
      )

      this.$emit('saved', {
        ...this.model,
        located_in_country: country,
      })
    },
    async findCompanies(query) {
      return await findCompanies(query)
    },
  },
}
</script>
