<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue">Choose your products/services</span>
  </header>
  <main class="s-modal-body">
    <p class="blue">
      These strongly relate to your position in the value chain, so you'll be
      asked to first indicate that as well.
    </p>
    <dual-pane-select
      v-model="selected"
      :options="products"
      label="product/services"
    ></dual-pane-select>
  </main>

  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
import DualPaneSelect from '../components/DualPaneSelect.vue'
import { orderBy } from 'lodash-es'

export default {
  name: 'ProductDialog',
  components: { DualPaneSelect },
  emits: ['close', 'saved'],
  props: ['organization', 'options'],
  inheritAttrs: false,
  data() {
    return {
      dirty: false,
      products: [],
      selected: [],
    }
  },
  async mounted() {
    this.products = this.options.products.map((product) => {
      return {
        value: product.product,
        label: product.path,
      }
    })

    if (this.organization.products) {
      this.organization.products.forEach((product) => {
        this.selected.push(
          this.products.find((item) => item.value === product.value)
        )
      })
    }

    this.selected = orderBy(this.selected, 'label')

    this.$watch(
      'selected',
      () => {
        this.dirty = true
      },
      { deep: true }
    )
  },
  methods: {
    async save() {
      this.$emit('saved', {
        products: this.selected.map((element) => {
          return {
            value: element.value,
            label: element.label,
          }
        }),
      })
    },
  },
}
</script>
