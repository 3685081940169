<template>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8 notification gray text-center mt-5">
      <p class="bold margin-0">
        Do you experience any issues during registration?
      </p>
      <p class="margin-0">
        Please click <a :href="urls.reset">here</a> to restart the process or
        reach out directly to
        <a href="mailto:web@solarplaza.com">web@solarplaza.com</a>
      </p>
    </div>
    <div class="col-md-2"></div>
  </div>
</template>
<script>
export default {
  name: 'ResetRegistration',
  props: {
    urls: {
      type: Object,
      required: true,
    },
  },
}
</script>
