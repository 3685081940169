<template>
  <div v-if="systemError" class="system-error">
    <div class="notification red mb-3">
      <i
        @click.prevent="systemError = false"
        class="fa-regular fa-xmark close"
      ></i>
      <i class="fa-light fa-circle-exclamation"></i>
      <p class="inline large bold margin-0">Apologies, something went wrong</p>
      <p class="margin-0">
        Our technical staff has been notified and will resolve the issue as soon
        as possible.<br />
        Please reach out to
        <a href="mailto:web@solarplaza.com">web@solarplaza.com</a> if you need
        any assistance.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemError',
  props: {
    systemError: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped></style>
<script setup></script>
