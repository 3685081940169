function getCsrfToken() {
  return document
    .querySelector("meta[name='csrf-token']")
    .getAttribute('content')
}

function post(url, payload) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.headers.get('content-type') === 'application/json') {
          resolve(response.json())
        } else {
          resolve(response)
        }
      })
      .catch((error) => reject(error))
  })
}

function patch(url, payload) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.headers.get('content-type') === 'application/json') {
          resolve(response.json())
        } else {
          resolve(response)
        }
      })
      .catch((error) => reject(error))
  })
}

function get(url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    })
      .then((response) => {
        if (response.headers.get('content-type') === 'application/json') {
          resolve(response.json())
        } else {
          resolve(response)
        }
      })
      .catch((error) => reject(error))
  })
}

function getAllTags() {
  return get('/directory/tags/')
}

function getOrganizationTypes() {
  return get('/directory/organization-types/')
}

function getProductsAndServices() {
  return get('/directory/products-services/')
}

function getOrganizationOptions() {
  return get('/directory/options/')
}

function getGeographies(type) {
  return get(`/directory/geographies/${type}`)
}

function findCompanies(query) {
  return get(`/directory/find-organization/?q=${query}`)
}

export {
  get,
  patch,
  post,
  getAllTags,
  getOrganizationOptions,
  getOrganizationTypes,
  getProductsAndServices,
  getGeographies,
  findCompanies,
}
