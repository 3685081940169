<template>
  <h3 class="display-4 bold blue align-center">Tell us more about you</h3>

  <organization-select
    :form="form"
    :csrfToken="csrfToken"
    :errors="errors"
    :urls="urls"
    :selectOrganization="selectOrganization"
    :clearSelectedOrganization="clearSelectedOrganization"
    :selectedOrganization="selectedOrganization"
    :goToPersonalStep="goToPersonalStep"
    :validate="validate"
  ></organization-select>

  <label for="id_job_title">Job title:</label>
  <input
    id="id_job_title"
    type="text"
    name="job_title"
    v-model="form.job_title"
    maxlength="150"
    @change="validate(['job_title'])"
    @focusout="validate(['job_title'])"
  />

  <FormErrors :errors="errors.job_title"></FormErrors>

  <label for="id_country">Country:</label>
  <select
    v-model="form.country"
    id="id_country"
    class="mb-4"
    name="country"
    @change="validate(['country'])"
    @focusout="validate(['country'])"
  >
    <option value="">Select country</option>
    <option v-for="country of countries" :value="country.id">
      {{ country.name }}
    </option>
  </select>
  <TermsAndConditions :urls="urls" :form="form" />

  <button
    class="orange small w-100"
    @click.prevent="save"
    :disabled="saveButtonDisabled"
  >
    Create free account
  </button>
</template>

<script>
import FormErrors from '../../components/FormFieldErrors.vue'
import OrganizationSelect from './OrganizationSelect.vue'
import TermsAndConditions from './TermsAndConditions.vue'

export default {
  name: 'BusinessDetails',
  components: {
    OrganizationSelect,
    FormErrors,
    TermsAndConditions,
  },
  props: {
    form: Object,
    errors: Object,
    pendingRequired: Boolean,
    csrfToken: String,
    urls: Object,
    selectOrganization: Function,
    clearSelectedOrganization: Function,
    goToPersonalStep: Function,
    save: Function,
    validate: Function,
    selectedOrganization: Object,
    countries: Array,
    saveButtonDisabled: Boolean,
  },
}
</script>
