<template>
  <div id="app" class="row">
    <div class="col-12">
      <img
        class="solarplaza-logo"
        v-bind:src="urls.solarplazaLogo"
        alt="Solarplaza - Logo"
      />
      <system-error :system-error="systemError" />
      <div v-if="currentStep === 'personal'">
        <h3 class="display-4 bold blue align-center">
          Create <em class="green">free</em> account within 2 minutes
        </h3>
        <p class="align-center margin-2">
          Already have an account? <a :href="urls.login">Log in</a>
        </p>
      </div>

      <div v-if="currentStep === 'confirm'">
        <h3 class="display-4 bold blue align-center">
          Please verify your e-mail address
        </h3>
      </div>

      <div v-if="currentStep === 'done'">
        <h3 class="display-4 bold blue align-center">Congratulations!</h3>
      </div>
    </div>

    <div id="steps" class="d-none d-sm-block col-sm-5 col-lg-4">
      <ol>
        <li
          v-for="step of steps"
          :class="step.name === currentStep ? 'active' : ''"
        >
          <div class="step-mark"></div>
          {{ step.label }}
        </li>
      </ol>
    </div>
    <div id="steps" class="col-12 col-sm-7 col-lg-4">
      <div v-if="currentStep === 'personal'">
        <label for="id_first_name">First name:</label>
        <input
          type="text"
          name="first_name"
          v-model="form.first_name"
          maxlength="150"
          id="id_first_name"
          @change="validate(['first_name'])"
          @focusout="validate(['first_name'])"
        />

        <FormErrors :errors="errors.first_name"></FormErrors>

        <label for="id_last_name">Last name:</label>
        <input
          type="text"
          name="last_name"
          v-model="form.last_name"
          maxlength="150"
          id="id_last_name"
          @change="validate(['last_name'])"
          @focusout="validate(['last_name'])"
        />

        <FormErrors :errors="errors.last_name"></FormErrors>

        <label for="id_email" v-if="kind === 'business'">Work email:</label>
        <label for="id_email" v-else>Email:</label>
        <input
          type="email"
          name="email"
          v-model="form.email"
          maxlength="254"
          id="id_email"
          @change="validate(['email'])"
          @input="delayedValidate(['email'])"
          @focusout="validate(['email'])"
        />

        <FormErrors :errors="errors.email"></FormErrors>

        <div
          class="notification yellow mb-2 margin-n collapse"
          id="accountinfo"
        >
          <i class="fa-sharp fa-regular fa-circle-info"></i>
          <p class="inline">
            Our platform is primarily focused on professionals active in the
            renewable energy industry. Registering with your business email
            address allows you to:
          </p>
          <ul class="disc mb-2">
            <li>Connect to and promote your organization</li>
            <li>Register for our business events</li>
            <li>Make use of various professional features on our platform</li>
          </ul>
          <p>
            Creating a
            <a :href="urls.registerPersonal">private account</a>
            is possible, but functionalities might be limited.
          </p>
        </div>

        <label for="id_password">Password:</label>
        <input
          type="password"
          name="password"
          v-model="form.password"
          autocomplete="new-password"
          required
          id="id_password"
          class="mb-4"
          @change="validate(['password'])"
          @input="delayedValidate(['password'])"
          @focusout="validate(['password'])"
        />

        <FormErrors :errors="errors.password"></FormErrors>

        <TermsAndConditions
          v-if="kind === 'personal'"
          :urls="urls"
          :form="form"
        />

        <button
          class="orange small align-center w-100"
          v-if="kind === 'business'"
          @click.prevent="goToBusinessStep"
          :disabled="!personalDataValid"
        >
          Go to business details
        </button>
        <button
          class="orange small align-center"
          v-else
          @click.prevent="save"
          :disabled="saveButtonDisabled"
        >
          Create free account
        </button>
      </div>

      <business-details
        v-if="currentStep === 'business'"
        :form="form"
        :errors="errors"
        :pendingRequired="pendingRequired"
        :csrfToken="csrfToken"
        :countries="countries"
        :urls="urls"
        :selectOrganization="selectOrganization"
        :clearSelectedOrganization="clearSelectedOrganization"
        :selectedOrganization="selectedOrganization"
        :saveButtonDisabled="saveButtonDisabled"
        :goToPersonalStep="goToPersonalStep"
        :save="save"
        :validate="validate"
      ></business-details>

      <div
        v-if="currentStep === 'confirm' || currentStep === 'done'"
        v-html="serverSideContent"
      ></div>
    </div>

    <div class="d-none d-lg-block col-md-4">
      <div class="usp-box usp-form">
        <div class="row usp">
          <div class="col-1"><i class="far fa-check"></i></div>
          <div class="col-10">
            <p class="blue">Access & bookmark 1,000+ knowledge resources</p>
          </div>
        </div>
        <div class="row usp">
          <div class="col-1"><i class="far fa-check"></i></div>
          <div class="col-10">
            <p class="blue">
              Search, filter & pinpoint PV industry organizations from our
              directory (3,000+ and counting)
            </p>
          </div>
        </div>
        <div v-if="kind === 'business'" class="row usp">
          <div class="col-1"><i class="far fa-check"></i></div>
          <div class="col-10">
            <p class="blue">
              Finetune & promote your organization's profile in the directory
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="currentStep != 'personal'" class="row">
    <div class="col-12 notification gray text-center mt-5">
      <p class="bold margin-0">
        Do you experience any issues during registration?
      </p>
      <p>
        Please click <a href="#" @click.prevent="goToPersonalStep">here</a> to
        restart the process or reach out directly to
        <a href="mailto:web@solarplaza.com">web@solarplaza.com</a>
      </p>
    </div>
  </div>
</template>

<script src="./Registration.js"></script>
