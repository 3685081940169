<template>
  <div class="row">
    <div class="col-md-8 billing-information">
      <h3 class="display-3 bold blue-light margin-2">
        Business Account Required
      </h3>

      <div class="row">
        <p>
          You're currently logged in to our platform with a
          <b><i>personal account</i></b
          >, which is not linked to an organization and has limited
          functionality because of that. In order to purchase a ticket, a (free)
          business account is required.<br />
          Please
          <a :href="urls.reregister"
            >re-register to our platform as a business user</a
          >
          to complete your purchase.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessAccountRequired',
  props: {
    urls: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
<script setup></script>
