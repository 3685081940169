export default class {
  constructor() {
    this.initResourceForm()
  }

  initResourceForm() {
    $('#id_event_optin').on('click', (event) => {
      $('#submit-resource').toggleClass('inactive')
    })
  }
}
