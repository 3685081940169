import { ref } from 'vue'

export default function useBackend() {
  const systemError = ref(false)

  function callBackend({ url, csrfToken, payload }) {
    return new Promise((resolve, reject) => {
      console.log('callBackend', JSON.parse(JSON.stringify(payload)))
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (response.status !== 200) {
            console.error('callBackend error', response)
            systemError.value = true
            reject('Error: ' + response.status)
          } else {
            response
              .json()
              .then((data) => {
                console.log('callBackend response', data)
                resolve(data)
                console.log('callBackend resolve done')
              })
              .catch((err) => {
                console.error('JSON error', err)
                systemError.value = true
                reject(err)
              })
          }
        })
        .catch((err) => {
          console.error('callBackend error', err)
          systemError.value = true
          reject(err)
        })
    })
  }

  return { callBackend, systemError }
}
