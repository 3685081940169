<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue">Name & About</span>
  </header>
  <main class="s-modal-body">
    <FormKit
      type="form"
      :actions="false"
      v-model="organizationDetails"
      @change.native="dirty = true"
    >
      <FormKit type="text" name="name" label="Name" />
      <FormKit type="textarea" name="description" label="About" />
    </FormKit>
  </main>

  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
export default {
  name: 'OrganizationNameDialog',
  emits: ['close', 'saved'],
  props: ['organization'],
  inheritAttrs: false,
  data() {
    return {
      dirty: false,
      organizationDetails: {
        name: this.organization.name,
        description: this.organization.description,
      },
    }
  },
  methods: {
    async save() {
      this.$emit('saved', {
        name: this.organizationDetails.name,
        description: this.organizationDetails.description,
      })
    },
  },
}
</script>
