<template>
  <div :style="style" v-show="parent === null || parentChecked || checked">
    <input
      class="margin-0"
      v-if="type === 'checkbox_multiple'"
      type="checkbox"
      :checked="checked"
      @change="changed"
      ref="checkbox"
      :id="id"
    />
    <input
      v-else
      type="radio"
      :checked="checked"
      @change="changed"
      ref="checkbox"
      style="margin-bottom: 4px"
      :name="facetName"
      :id="id"
    />
    <label
      :class="displayClass"
      style="margin-left: 4px; display: inline-flex"
      :for="id"
      >{{ label }}</label
    >
  </div>
</template>

<script>
import isNumber from 'lodash-es/isNumber'

export default {
  name: 'FacetNode',
  props: {
    facetName: String,
    value: String,
    selectedChoices: Object,
    quantity: Number,
    type: String,
  },
  emits: ['change'],
  data() {
    const elements = this.value.split(' > ')
    const parent =
      elements.length > 1
        ? elements.slice(0, elements.length - 1).join(' > ')
        : null

    let label = elements[elements.length - 1]
    if (this.quantity && isNumber(this.quantity) && this.facetName !== 'date') {
      label += ` (${this.quantity})`
    }
    const relevantChoices =
      (this.selectedChoices && this.selectedChoices[this.facetName]) || []
    return {
      parent: parent,
      checked: relevantChoices.includes(this.value),
      label: label,
      style: `margin-left: ${(elements.length - 1) * 29}px;`,
      displayClass:
        elements.length > 1 ? 'display-7 margin-0' : 'display-6 margin-0',
      parentChecked: relevantChoices.includes(parent),
      id: Math.floor(Math.random() * 10000),
    }
  },
  async mounted() {
    this.$watch(
      'selectedChoices',
      (newValue) => {
        const relevantChoices = newValue[this.facetName] || []
        this.checked = relevantChoices.includes(this.value)
        this.parentChecked = relevantChoices.includes(this.parent)
      },
      { deep: true }
    )
  },
  methods: {
    changed() {
      this.$emit('change', {
        name: this.facetName,
        value: this.value,
        checked: this.$refs.checkbox.checked,
        type: this.type,
      })
    },
  },
}
</script>

<style scoped></style>
