<template>
  <div class="search-bar-section" :style="{ 'background-image': visual }">
    <div class="container">
      <div class="row">
        <div class="col-12 center">
          <h1 class="visually-hidden" v-if="heading === 'Network'">
            Solarplaza Networking Database
          </h1>
          <h1 class="visually-hidden" v-if="heading === 'Resources'">
            Solarplaza Energy Resources
          </h1>
          <h2 class="display-1 bold white hero-title">{{ heading }}</h2>
        </div>

        <div class="search-bar col-12 center">
          <input
            class="search-text-box"
            type="text"
            id="query"
            name="query"
            autocomplete="off"
            v-model="query"
            @keyup.enter="pushStateAndLoadResults"
            @keyup="updateCurrentWord"
            placeholder="Start typing and selecting keywords..."
          />

          <button
            class="orange medium search-button"
            @click.prevent="pushStateAndLoadResults"
          >
            <i class="far fa-search icon"></i>
          </button>
        </div>

        <div class="selected-filters">
          <div v-if="suggestedFilters.length">
            <ul>
              <li
                v-for="suggested in suggestedFilters"
                v-bind:class="suggested.key"
              >
                <a
                  href=""
                  @click.prevent="
                    addFilterReplacingWord(
                      suggested.key,
                      suggested.value,
                      currentWord
                    )
                  "
                  ><p class="tag blue">
                    <span
                      class="blue"
                      v-if="
                        suggested.key === 'country' && heading === 'Network'
                      "
                      >HQ in</span
                    >
                    <span
                      class="blue"
                      v-if="
                        suggested.key === 'geography' && heading === 'Network'
                      "
                      >Active in</span
                    >
                    {{ suggested.value
                    }}<i class="fa-light fa-circle-plus"></i></p
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-5 col-md-4 col-lg-3"></div>
      <div class="col-12 col-sm-7 col-md-8 col-lg-9">
        <p class="display-6 blue hide-on-mobile">{{ resultCount }}</p>
        <div class="row">
          <div class="col-md-8 active-filters">
            <p class="display-5 blue me-3 mt-1 hide-on-mobile">
              Chosen filters:
            </p>
            <ul class="">
              <template v-for="key in Object.keys(selectedFilters)">
                <li v-for="value in selectedFilters[key]">
                  <a
                    href=""
                    @click.prevent="removeFilter(key, value)"
                    data-field="{{ field }}"
                    data-value="{{ value }}"
                    class="tag blue"
                    >{{ value }} <i class="fa-light fa-circle-xmark"></i
                  ></a>
                </li>
              </template>
            </ul>
          </div>
          <div class="col-12 col-md-4" v-if="searchType === 'network'">
            <select
              class="float-end"
              v-model="sortMode"
              style="
                height: 40px;
                width: 200px;
                font-family: FontAwesome, Arboria;
              "
              @change="sortingAdjusted()"
            >
              <option value="relevance">Relevance</option>
              <option value="employeeCountDesc">&#xf107; Employee size</option>
              <option value="employeeCountAsc">&#xf106; Employee size</option>
              <option value="foundedInAsc">&#xf107; Years of existence</option>
              <option value="foundedInDesc">&#xf106; Years of existence</option>
            </select>
            <label class="display-6 blue inline float-end me-3 mt-2"
              >Sort by:</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="organization-results">
      <div class="col-12 col-sm-5 col-md-4 col-lg-3">
        <div class="filter-section">
          <div
            class="show-filters d-block d-sm-none center mt-2"
            data-bs-toggle="collapse"
            data-bs-target="#filters"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <div
              class="button medium blue-light d-block mx-auto text-center mb-3"
            >
              Filter {{ resultCount }} <i class="fa-solid fa-sliders"></i>
            </div>
          </div>
          <div id="filters" class="collapse collapse-mobile center">
            <div
              class="filter"
              v-for="(field, index) in facetFields"
              :class="getClassForFilterList(field)"
            >
              <div
                class="filter-topic"
                :id="field.name"
                @click="clickFilterList(field.name)"
              >
                <p class="display-5 margin-0 blue bold">
                  {{ field.label }}
                  <i
                    v-if="listVisible(field.name)"
                    class="fas fa-chevron-up"
                  ></i>
                  <i v-else class="fas fa-chevron-down"></i>
                </p>
              </div>
              <section class="margin-0 filters">
                <template
                  v-for="choice in field.choices"
                  :key="`${field.name}_${choice[0]}_${choice[1]}`"
                >
                  <facet-node
                    :facet-name="field.name"
                    :selected-choices="selectedFilters"
                    :type="field.type"
                    :value="choice[0]"
                    :quantity="choice[1]"
                    @change="selectionChanged"
                  ></facet-node>
                </template>
              </section>
              {{ field.errors }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-7 col-md-8 col-lg-9">
        <div v-html="searchResultsHtml"></div>
        <div class="row">
          <a
            v-if="hasMoreResults"
            href=""
            @click.prevent="loadMoreResults"
            class="display-5 align-center mt-4"
          >
            Load more results <i class="fas fa-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FacetNode from './FacetNode.vue'

export default {
  name: 'Search',
  components: { FacetNode },
  data() {
    return {
      csrfToken: '',
      searchUrl: '',
      searchUrlJson: '',
      heading: '',
      query: '',
      facetFields: [],
      resultCount: '',
      hasMoreResults: false,
      selectedFilters: {},
      openedFilterLists: ['organization_type', 'geography', 'product'],
      possibleFilters: [],
      searchResultsHtml: '',
      currentWord: {
        word: '',
        startPos: 0,
        endPos: 0,
      },
      page: 0,
      sortMode: 'relevance',
      searchType: '',
    }
  },
  mounted() {
    this.loadInitialData()
    this.loadResults()
    // let that = this
    // window.addEventListener('popstate', (event) => {
    //   let restoredState = JSON.parse(event.state)
    //   if (restoredState) {
    //     console.log(restoredState)
    //     Object.assign(store, restoredState)
    //     window.restoredState = restoredState
    //   } else {
    //     that.loadInitialData()
    //     that.loadResults()
    //   }
    // })

    this.searchType = document
      .getElementById('vue-search')
      .getAttribute('data-search-type')
  },
  computed: {
    suggestedFilters() {
      let suggested = []
      let word = this.currentWord.word
      if (!word) {
        return suggested
      }
      for (let possibleFilterGroup of this.possibleFilters) {
        let name = possibleFilterGroup.name
        for (let value of possibleFilterGroup.values) {
          if (
            this.selectedFilters[name] &&
            this.selectedFilters[name].includes(value)
          ) {
            continue
          }
          if (value.toLowerCase().indexOf(word.toLowerCase()) > -1) {
            suggested.push({ key: name, value: value })
          }
        }
      }
      return suggested
    },
  },
  methods: {
    loadInitialData() {
      let initialData = JSON.parse(
        document.getElementById('initialData').textContent
      )
      this.csrfToken = initialData.csrfToken
      this.possibleFilters = initialData.possibleFilters
      this.query = initialData.query
      this.searchUrl = initialData.searchUrl
      this.searchUrlJson = initialData.searchUrlJson
      this.visual = initialData.visual
      this.heading = initialData.heading
      this.selectedFilters = initialData.selectedFilters || {}
    },
    selectionChanged({ name, value, checked, type }) {
      if (type === 'checkbox_multiple') {
        this.$nextTick(() => {
          if (checked) {
            this.addFilter(name, value)
          } else {
            this.removeFilter(name, value)
          }
        })
      } else {
        let values = []
        if (checked) {
          values.push(value)
        }
        this.setFilter(name, values)
      }
    },
    setFilter(key, values) {
      this.selectedFilters[key] = values
      this.page = 0
      this.pushStateAndLoadResults()
    },
    getClassForFilterList(field) {
      let classOpenState =
        this.openedFilterLists.includes(field.name) ||
        (this.selectedFilters[field.name] &&
          this.selectedFilters[field.name].length > 0)
          ? 'filter-list-open'
          : 'filter-list-closed'
      return `${classOpenState} ${field.class}`
    },
    updateCurrentWord() {
      let query_el = document.getElementById('query')
      let caretPosition = query_el.selectionStart
      let wordStart = 0
      let wordEnd = 0
      for (
        let startScanPos = caretPosition - 1;
        startScanPos >= 0;
        startScanPos--
      ) {
        if (query_el.value[startScanPos] === ' ') {
          wordStart = startScanPos + 1
          break
        } else if (startScanPos === 0) {
          wordStart = 0
          break
        }
      }
      for (
        let endScanPos = wordStart;
        endScanPos <= query_el.value.length;
        endScanPos++
      ) {
        if (query_el.value[endScanPos] === ' ') {
          wordEnd = endScanPos - 1
          break
        } else if (endScanPos === query_el.value.length) {
          wordEnd = endScanPos
          break
        }
      }

      this.currentWord.word = query_el.value.substring(wordStart, wordEnd + 1)
      this.currentWord.startPos = wordStart
      this.currentWord.endPos = wordEnd
    },
    addFilterReplacingWord(name, value, word) {
      this.query =
        this.query.substring(0, word.startPos) +
        this.query.substring(word.endPos + 1)
      this.currentWord = {
        word: '',
        startPos: 0,
        endPos: 0,
      }
      this.addFilter(name, value)
      document.getElementById('query').focus()
    },
    historyPushState() {
      console.debug('Pushing state')
      history.pushState(
        this.getHistoryState(),
        document.title,
        this.getUrlForState()
      )
    },
    getUrlForState: function () {
      let filterParams = ''
      for (let name in this.selectedFilters) {
        for (let value of this.selectedFilters[name]) {
          filterParams += '&' + name + '=' + encodeURIComponent(value)
        }
      }
      return (
        this.searchUrl +
        '?query=' +
        encodeURIComponent(this.query) +
        filterParams
      )
    },
    pushStateAndLoadResults: function () {
      console.debug('pushStateAndLoadResults')
      this.loadResults()
      this.historyPushState()
    },
    loadMoreResults() {
      this.page += 1
      this.loadResults()
    },
    sortingAdjusted() {
      this.loadResults()
    },
    loadResults() {
      let data = JSON.stringify({
        query: this.query,
        page: this.page,
        ...this.selectedFilters,
        sortMode: this.sortMode,
      })
      console.debug(`loadResults: ${data}`)

      // this.facetFields = []

      $.ajax(this.searchUrlJson, {
        type: 'POST',
        headers: {
          'X-CSRFToken': this.csrfToken,
          'Content-Type': 'application/json',
        },
        dataType: 'json',
        data: data,
        success: (data) => {
          this.facetFields = data.facetFields
          this.resultCount = data.resultCount
          this.hasMoreResults = data.hasMoreResults
          if (this.page === 0) {
            this.searchResultsHtml = data.searchResultsHtml
          } else {
            this.searchResultsHtml += data.searchResultsHtml
          }
          // this.$forceUpdate()
        },
        error: (data) => {
          console.error('error', data)
        },
      })
    },
    getHistoryState() {
      console.log('getHistoryState')
      return JSON.stringify({
        query: this.query,
        facetFields: this.facetFields,
        page: this.page,
        selectedFilters: this.selectedFilters,
        openedFilterLists: this.openedFilterLists,
        resultCount: this.resultCount,
        searchResultsHtml: this.searchResultsHtml,
      })
    },
    addFilter(key, value) {
      if (!this.selectedFilters[key]) {
        this.selectedFilters[key] = []
      }
      this.selectedFilters[key].push(value)
      this.page = 0
      this.pushStateAndLoadResults()
    },
    removeFilter(key, value) {
      this.selectedFilters[key] = this.selectedFilters[key].filter(
        (currentValue) => !(currentValue === value)
      )
      this.page = 0
      this.pushStateAndLoadResults()
    },
    listVisible(name) {
      return this.openedFilterLists.includes(name)
    },
    clickFilterList(name) {
      if (!this.openedFilterLists.includes(name)) {
        this.openedFilterLists.push(name)
      } else {
        this.openedFilterLists = this.openedFilterLists.filter(
          (currentValue) => !(currentValue === name)
        )
      }
    },
  },
}
</script>
