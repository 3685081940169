<template>
  <div id="app" class="row" v-if="products.length > 0">
    <system-error :system-error="systemError" />
    <div class="col-12">
      <ticket-selection
        v-if="currentStep === 'tickets'"
        :event="event"
        :tickets="tickets"
        :products="products"
        :totals="summary.totals"
        :discountTotals="summary.discountTotals"
        :currentStepLabel="currentStepLabel"
        :getError="getError"
        :getStepErrors="getStepErrors"
        :urls="urls"
        @validate="validate"
        @delayed-validate="delayedValidate"
        @done-tickets="doneTickets"
      />
      <basket
        v-if="currentStep === 'basket'"
        :event="event"
        :tickets="tickets"
        :summary="summary"
        :currentStepLabel="currentStepLabel"
        :isAuthenticated="account.isAuthenticated"
        :urls="urls"
        :csrfToken="csrfToken"
        @done-basket="doneBasket"
        @go-to-step="goToStep"
        :getError="getError"
      />
      <billing
        v-if="currentStep === 'billing' && account.kind === 'business'"
        :event="event"
        :billing="billing"
        :urls="urls"
        :summary="summary"
        :countries="countries"
        :states="states"
        :hidden-fields="hiddenFields"
        :getError="getError"
        @done-billing="doneBilling"
        @validate="validate"
        @delayed-validate="delayedValidate"
      />
      <business-account-required
        v-else-if="currentStep === 'billing'"
        :urls="urls"
      />
      <attendees
        v-if="currentStep === 'attendees'"
        :attendees="attendees"
        :organizationTypes="organizationTypes"
        :dietaryOptions="dietaryOptions"
        :event="event"
        :fields="[
          'firstName',
          'lastName',
          'organizationName',
          'organizationType',
          'jobTitle',
          'email',
          'phone',
          'dietary',
        ]"
        :products="products"
        :summary="summary"
        :continueAllowed="attendeesContinueAllowed"
        :getError="getError"
        :getStepErrors="getStepErrors"
        :urls="urls"
        @done-attendees="doneAttendees"
        @validate="validate"
        @delayed-validate="delayedValidate"
      />
      <payment
        v-if="currentStep === 'payment'"
        :event="event"
        :urls="urls"
        :summary="summary"
        :products="products"
        :attendees="attendees"
        :payment="payment"
        :getError="getError"
        @done-payment="donePayment"
        @go-to-step="goToStep"
      />
    </div>
  </div>

  <reset-registration :urls="urls" v-if="products.length > 0" />

  <steps-menu
    :showStepsMenu="showStepsMenu"
    :steps="steps"
    :currentStep="currentStep"
    :urls="urls"
    @go-to-step="goToStep"
  />
</template>

<script src="./Tickets.js"></script>
