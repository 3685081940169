export default class {
  constructor() {
    this.init()
  }

  init() {
    $('.expand-box').on('click', (event) => {
      let $target = $(event.currentTarget)
      $($target).find('.description').slideToggle()
    })
  }
}
