<template>
  <div id="app" class="row">
    <system-error :system-error="systemError" />
    <div class="col-12">
      <div class="row">
        <div class="col-md-6 personal-information">
          <h3 class="display-3 margin-2 bold blue-light">
            Selecting Your Add-ons
          </h3>

          <p class="large margin-2">
            As a confirmed event attendee, with your main ticket already taken
            care of, you have the option to purchase additional add-ons to
            upgrade your event experience.
          </p>
        </div>
      </div>
      <attendees
        v-if="currentStep === 'attendees'"
        :attendees="attendees"
        :dietaryOptions="dietaryOptions"
        :event="event"
        :fields="['dietary']"
        :products="products"
        :summary="summary"
        :continueAllowed="attendeesContinueAllowed"
        :getError="getError"
        :urls="urls"
        :getStepErrors="getStepErrors"
        @done-attendees="doneAttendees"
        @validate="validate"
        @delayed-validate="delayedValidate"
      />
      <billing
        v-if="currentStep === 'billing'"
        :event="event"
        :billing="billing"
        :urls="urls"
        :summary="summary"
        :countries="countries"
        :states="states"
        :hidden-fields="hiddenFields"
        :getError="getError"
        @done-billing="doneBilling"
        @validate="validate"
        @delayed-validate="delayedValidate"
      />
      <payment
        v-if="currentStep === 'payment'"
        :event="event"
        :urls="urls"
        :summary="summary"
        :products="products"
        :attendees="attendees"
        :payment="payment"
        :getError="getError"
        @done-payment="donePayment"
        @go-to-step="goToStep"
      />
    </div>
  </div>

  <reset-registration :urls="urls" />

  <steps-menu
    :showStepsMenu="showStepsMenu"
    :steps="steps"
    :currentStep="currentStep"
    :urls="urls"
    @go-to-step="goToStep"
  />
</template>

<script src="./AddAddOns.js"></script>

<style scoped></style>
