<template>
  <div class="card mb-5" v-if="!finished">
    <div class="card-body">
      <h2 class="card-title display-5">
        Do you want your name to be shown in the attendee list of this event?
      </h2>
      This means other attendees can see that you are attending, and contact you
      if they want to.
    </div>
    <div class="card-footer row p-4">
      <label id="accept-for-future-event"
        ><input
          type="checkbox"
          class="mx-3"
          v-model="model.rememberChoice"
          id="accept-for-future-events"
        />
        Remember my choice for future events</label
      >
      <div class="col-auto mb-4">
        <button @click="accept" class="button orange small">Accept</button>
      </div>
      <div class="col-auto">
        <button @click="decline" class="button orange outline small">
          Decline
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '../../api'

export default {
  name: 'EventAttendeeStatusDialog',
  data() {
    return {
      loading: false,
      finished: false,
      model: {
        rememberChoice: true,
      },
    }
  },
  props: {
    event: {
      type: String,
      required: true,
    },
  },
  methods: {
    accept() {
      post(`/event/${this.event}/update-attendee-status/`, {
        show_in_attendee_list: true,
        always_show_in_attendee_list: this.model.rememberChoice,
      }).then(() => {
        this.finished = true
        location.reload()
      })
    },
    decline() {
      post(`/event/${this.event}/update-attendee-status/`, {
        show_in_attendee_list: false,
        always_show_in_attendee_list: this.model.rememberChoice,
      }).then(() => {
        this.finished = true
      })
    },
  },
}
</script>

<style scoped></style>
