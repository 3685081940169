import { createApp } from 'vue'

import Search from './Search.vue'

export default class {
  constructor() {
    this.$searchForm = $('form.search')
    if (!this.$searchForm.length === 0) {
      return
    }
    this.initVueApp()
  }

  initVueApp() {
    createApp({
      template: '<Search/>',
      components: {
        Search,
      },
    }).mount('#vue-search')
  }
}
