<template>
  <header class="s-modal-header">
    <span class="display-4 bold blue">Edit logo</span>
  </header>
  <main style="height: 300px">
    <section v-if="!displayCropper">
      <image-upload @change="onImageUpload"></image-upload>
      <footer class="s-flex-horizontal-right">
        <span class="display-9">Drop image here or click to select file</span>
      </footer>
    </section>
    <section v-else>
      <section style="height: 240px">
        <image-cropper
          :src="src"
          style="height: 240px; width: 400px"
          ref="cropper"
          @change="() => (this.dirty = true)"
        ></image-cropper>
      </section>
      <footer class="s-flex-horizontal-right" style="padding: 8px">
        <i class="fas fa-trash icon-button" @click="clear()"></i>
      </footer>
    </section>
  </main>
  <footer class="s-modal-footer">
    <button class="small cancel" @click="$emit('close')">Cancel</button>
    <button class="orange small" @click="save()" :disabled="!dirty">
      Save
    </button>
  </footer>
</template>

<script>
import ImageUpload from '../components/ImageUpload.vue'
import ImageCropper from '../components/ImageCropper.vue'
import { post } from '../api'

export default {
  name: 'LogoDialog',
  components: { ImageCropper, ImageUpload },
  emits: ['close', 'saved'],
  props: ['organization', 'urls'],
  inheritAttrs: false,
  data() {
    return {
      model: {},
      dirty: false,
      src: undefined,
      displayCropper: false,
    }
  },
  async mounted() {
    if (this.organization.logo) {
      this.src = this.organization.logo

      // Check if file actually exists
      this.displayCropper = await this.imageExists(this.src)
    }
  },
  methods: {
    imageExists(url) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => {
          resolve(true)
        }
        image.onerror = () => {
          reject(false)
        }
        image.src = url
      })
    },
    updateOrganizationLogo(payload) {
      return post(this.urls.uploadOrganizationLogo, payload)
    },
    async save() {
      const dataUrl = this.$refs.cropper.cropper
        .getCroppedCanvas()
        .toDataURL('image/png')
      const result = await this.updateOrganizationLogo({
        logo: dataUrl,
      })
      this.$emit('saved', { logo: { url: result.url } })
    },
    onImageUpload(payload) {
      this.src = payload
      this.displayCropper = true
      this.dirty = true
    },
    clear() {
      this.src = undefined
      this.displayCropper = false
    },
  },
}
</script>

<style scoped></style>
