<template>
  <section
    contenteditable
    @input="filter"
    ref="input"
    class="input-text"
    @keydown.down.prevent="onArrowDown"
    @keydown.up.prevent="onArrowUp"
    @keydown.enter.prevent="onEnter"
    @keydown.esc.prevent="clear()"
  ></section>
  <section
    class="click-catch-area"
    v-if="displayResults"
    @click="clear"
  ></section>
  <ul
    class="tag-input-search-results"
    v-if="items.length > 0"
    :style="listStyle"
  >
    <li
      v-for="(item, index) in items"
      @click="add(item)"
      :class="{ selected: index === selectedIndex }"
    >
      <span>{{ item.label }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AutoComplete',
  props: {
    modelValue: {
      type: Object,
      default: {},
      description: 'expects label & value',
    },
    optionCallback: {
      type: Function,
    },
  },
  emits: ['update:modelValue', 'selected'],
  data() {
    return {
      items: [],
      displayResults: false,
      listStyle: '',
      content: {
        label: '',
      },
      selectedIndex: 0,
    }
  },
  mounted() {
    this.listStyle = `width: ${this.$refs.input.clientWidth}px`

    // It somehow takes some time for the modelValue to come through
    const stopWatch = this.$watch('modelValue.label', (value) => {
      this.$refs.input.innerText = value
      stopWatch()
    })
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    onArrowDown() {
      if (this.selectedIndex < this.items.length - 2) {
        this.selectedIndex = this.selectedIndex + 1
      }
    },
    onArrowUp() {
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1
      }
    },
    onEnter() {
      this.add(this.items[this.selectedIndex])
    },
    add(item) {
      this.content = item
      this.$refs.input.innerText = item.label

      this.$emit('update:modelValue', item)
      this.$emit('selected')
      this.clear()
    },
    async filter(event) {
      const query = event.target.innerText

      if (query.length > 2) {
        this.items = await this.optionCallback(query.toLowerCase())
      }
      this.selectedIndex = 0
    },
    clear() {
      this.items = []
    },
  },
}
</script>

<style scoped></style>
