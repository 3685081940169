import confettiLib from 'canvas-confetti'
export default class Confetti {
  constructor(confettiId) {
    this.confettiId = confettiId
    document.getElementById(confettiId)?.addEventListener('click', () => {
      this.initConfetti()
    })
  }
  initConfetti() {
    var confettiConfig = {
      particleCount: 300,
      spread: 250,
      colors: ['#ff1800', '#ff3200', '#ff6b00', '#ff9900', '#ffb546'],
      origin: { y: 0.5 },
      shapes: ['circle'],
    }
    this.addConfetti(confettiConfig)
  }
  addConfetti(confettiConfig) {
    confettiLib(confettiConfig)
  }
}
export { Confetti, confettiLib as confetti }
